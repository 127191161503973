import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribeForm from "../components/subscribe-form.js"

class Contact extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Contact Us" keywords={[`gatsby`, `Contact Us`, `react`]} />
        <SubscribeForm />
      </Layout>
    )
  }
}

export default Contact
